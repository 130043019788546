<template>
    <b-modal title="Administrator" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group label="Uporabniško ime">
                    <validation-provider #default="{ errors }" name="Uporabniško ime" rules="required">
                        <b-form-input v-model="addObject.username"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email">
                        <b-form-input v-model="addObject.email"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Geslo">
                    <validation-provider #default="{ errors }" name="Geslo" rules="required">
                        <b-form-input v-model="addObject.password" type="password"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <feather-icon icon="PlusIcon" class="mr-50"/>
                <span class="align-middle">Dodaj</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'

    export default {
        props: {
        },
        components: {
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                modalActive: false,
                addObject: {
                    username: '',
                    email: '',
                    password: ''
                },
                required,
                email
            }
        },
        methods: {
            open() {
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) this.add()
                })
            },
            async add() {
                try {
                    const response = await this.$http.post('/api/admin/v1/admin_user/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess('Dodajanje je bilo uspešno!')
                    this.addObject = {
                        username: '',
                        email: '',
                        password: ''
                    }
                } catch {
                    this.$printError('Prišlo je do napake')
                }
            }
        }
    }
</script>
