<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" title="Administratorji">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" @click="$refs.modal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        Dodaj administratorja
                    </b-button>
                </b-col>
            </b-row>
            <basic-table :columns="columns" :data="admins" v-slot="props">
                <span v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <b-button variant="danger" class="" @click="remove(admins[props.row.originalIndex].id)"><feather-icon icon="Trash2Icon"/></b-button>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>

        <AddAdmin ref="modal" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import BasicTable from '@/views/components/BasicTable'
    import AddAdmin from '@/views/Admin/AddAdmin'
    import {BButton, BCard, BCol, BOverlay, BRow} from 'bootstrap-vue'

    export default {
        components: {BasicTable, AddAdmin, BRow, BCol, BCard, BOverlay, BButton},
        data() {
            return {
                admins: [],
                columns: [
                    {
                        label: 'Uporabniško ime',
                        field: 'username',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Možnosti',
                        field: 'options',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ],
                showLoader: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true
                    const response = await this.$http.get('/api/admin/v1/admin_user/')
                    this.admins = response.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake')
                } finally {
                    this.showLoader = false
                }
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog('Ali res želite izbrisati vrstico?')
                    if (!confirmDelete) return
                    await this.$http.delete(`api/admin/v1/admin_user/${id}`)
                    this.$printSuccess('Brisanje je bilo uspešno!')
                    this.loadData()
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
